import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PageContainer from '../Base/PageContainer';
import { DateRangePicker } from 'react-dates';
import Preloader from '../Preloader';
import { getEntries, getContacts, searchEntries, exportEntries, exportContacts } from '../../services/entry';
import moment from 'moment';
import { HiEyeOff, HiOutlineFilter } from 'react-icons/hi';
import { toTimestamp } from '../../services/dates';
import LoadMore from '../Base/LoadMore';
import validator from 'validator';

import socket from '../../services/socket';
import { isMobile } from '../../services/responsive'
import ReferralModal from './ReferralModal';
import { Tooltip } from '@material-ui/core';

class ReferralPage extends React.Component{
    constructor(props){
        super(props);

        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            entries: [],
            contacts: [],
            loadingQueues: true,
            loadingEntries: true,
            loadingObjectFilters: true,
            entriesLimit: 25,
            entriesOffset: 25,
            hasMore: false,
            focusedInput: null,
            selectedEntry: undefined,
            loadingMore: false,
            searchTerm: '',
            isSearched: false,
            view: 'entries',
            filters: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment(),
                orderBy: 'desc'
            },
            objectFilterOptions: [],
            exporting: false,
            mobileFiltersShown: false
        }

        this.setEntries = this.setEntries.bind(this);
        this.handleAllInfoTrigger = this.handleAllInfoTrigger.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleExportContacts = this.handleExportContacts.bind(this);
        this.handleExportEntries = this.handleExportEntries.bind(this);
        this.handleFiltersTrigger = this.handleFiltersTrigger.bind(this);
        this.onChangeViewClick = this.onChangeViewClick.bind(this);
    }

    async componentDidMount(){
        this.setEntries();

        socket.on('entriesUpdate', ({ id, conversationId, entryId }) => {
            if(this.props.auth.selectedChatbot._id === id){
                this.setEntries();
            }
        });
    }

    componentWillUnmount(){
        socket.off('entriesUpdate');
    }

    handleAllInfoTrigger(entry){
        this.setState({ selectedEntry: entry })
    }

    handleLoadMore(){
        this.setState(prevState => ({
            entriesLimit: prevState.entriesLimit + prevState.entriesOffset,
            loadingMore: true
        }), () => {
            this.setEntries().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    handleSearchTerm(e){
        this.setState({ searchTerm: e.target.value });
    }

    handleClearSearch(){
        this.setState({ loadingEntries: true, searchTerm: '', isSearched: false }, () => { this.setEntries() });
    }

    handleSearchSubmit(e){
        e.preventDefault();
        this.setState({ loadingEntries: true })
        if(this.state.searchTerm.trim().length > 0){
            this.setState({ isSearched: true }, () => { this.setEntries() })
        }else{
            this.setState({ isSearched: false }, () => { this.setEntries() })
        }
    }

    async setEntries(){
        if(this.state.view === 'entries'){
            let entries = [];
            if(this.state.searchTerm.trim().length > 0){
                entries = await searchEntries(this.props.auth.selectedChatbot.token, this.state.entriesLimit + 1, this.state.searchTerm, this.state.filters)
            }else{
                entries = await getEntries(this.props.auth.selectedChatbot.token, this.state.entriesLimit + 1, this.state.filters)
            }
            this.setState({ loadingEntries: false, hasMore: entries.length > this.state.entriesLimit ? true : false });

            if(entries.length > this.state.entriesLimit){
                entries.pop()
            }

            this.setState({ entries })
        }else{
            let contacts = [];
            if(this.state.searchTerm.trim().length > 0){
                contacts = await searchEntries(this.props.auth.selectedChatbot.token, this.state.entriesLimit + 1, this.state.searchTerm, this.state.filters)
            }else{
                contacts = await getContacts(this.props.auth.selectedChatbot.token, this.state.entriesLimit + 1, this.state.filters)
            }
            this.setState({ loadingEntries: false, hasMore: contacts.length > this.state.entriesLimit ? true : false });

            if(contacts.length > this.state.entriesLimit){
                contacts.pop()
            }

            this.setState({ contacts })
        }
    }

    handleFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingEntries: true, filters: { ...prevState.filters, [fieldName]: value } }), () => {
            this.setEntries();
        })
    }

    async handleExportContacts(){
        this.setState({ exportingContacts: true });
        const response = await exportContacts(this.props.auth.selectedChatbot.token, this.state.filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Contacts.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.setState({ exportingContacts: false });
    }

    async handleExportEntries(){
        this.setState({ exportingEntries: true });
        const response = await exportEntries(this.props.auth.selectedChatbot.token, this.state.filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Entries.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.setState({ exportingEntries: false });
    }

    handleFiltersTrigger(){
        this.setState(prevState => ({ mobileFiltersShown: !prevState.mobileFiltersShown }))
    }

    onChangeViewClick(e){
        this.setState({ view: e, loadingEntries: true }, () => {
            this.setEntries();
        })
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                        <div className="page_title">Referrals</div>
                            {this.state.mobileFiltersShown ? <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiEyeOff/>Hide Filters</button> : <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiOutlineFilter/>Filters</button> }
                            <div className={`leads_top_bar ${this.state.mobileFiltersShown ? 'mobile_filters_shown' : ''}`}>
                                <div className="filters_column">
                                    <div className="filters">
                                        <div className="form-group">
                                            <label>Date Range</label>
                                            <DateRangePicker
                                                isOutsideRange={(day) => day.isAfter(moment())}
                                                noBorder={true}
                                                startDate={this.state.filters.startDate ? moment(this.state.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                                endDate={this.state.filters.endDate ? moment(this.state.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => { this.handleFilterChange('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.handleFilterChange('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                                                orientation={isMobile() ? 'vertical' : 'horizontal'}
                                                withFullScreenPortal={isMobile() ? true : false}
                                                initialVisibleMonth={() => moment().subtract(1, 'months')}
                                            />
                                        </div>
                                        <div className="top_bar_filter">
                                            <label>Sort by</label>
                                            <Select onChange={(e) => { this.handleFilterChange('orderBy', e.value) }} className="filter form_select" value={this.orderByOptions.find(orderOption => orderOption.value === this.state.filters.orderBy)} options={this.orderByOptions}/>
                                        </div>
                                    </div>
                                    {1 == 2 && (
                                        <div className="filters">
                                            <div className="top_bar_filter" style={{ width: "100%"}}>
                                                <label>Filter</label>
                                                <Select isLoading={this.state.loadingObjectFilters} onChange={(e) => { this.handleFilterChange('objectFilters', e.map(item => item.value)) }} className="flex-growform_select" isMulti options={this.state.objectFilterOptions}/>                                
                                                <button className="btn mb-medium mt-medium" disabled={this.state.exportingContacts} onClick={this.handleExportContacts}>{!this.state.exportingContacts ? 'Download Contacts as CSV' : <Preloader padding="0" size="15px" color="white"/>}</button>
                                            </div> 
                                        </div>
                                    )}
                                    <div className="filters">
                                        <div className="top_bar_filter flex-middle" style={{ width: "100%"}}>
                                            <div className='export_actions'>
                                                <button className="btn mb-medium mt-medium" disabled={this.state.exportingEntries} onClick={this.handleExportEntries}>{!this.state.exportingEntries ? 'Download Entries as CSV' : <Preloader padding="0" size="15px" color="white"/>}</button>
                                                <button className="btn mb-medium mt-medium" disabled={this.state.exportingContacts} onClick={this.handleExportContacts}>{!this.state.exportingContacts ? 'Download Contacts as CSV' : <Preloader padding="0" size="15px" color="white"/>}</button>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                                {/*<div className="search-container">
                                    <label>Search leads</label>
                                    <form className="help_request_search" onSubmit={this.handleSearchSubmit}>
                                        <input type="text" placeholder="Enter your search term" value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                        {this.state.isSearched && <div className="btn" onClick={this.handleClearSearch}><HiOutlineX/></div>}
                                        <button className="btn"><HiOutlineSearch/></button>
                                    </form>
                                </div>*/}
                            </div> 
                            <div className="leads_list">
                                <div className='referral_filters'>
                                    <div className='referral_filters__inner'>
                                        <button className={this.state.view === 'entries' ? 'selected' : ''} onClick={() => { this.onChangeViewClick('entries') }}>Entries</button>
                                        <button className={this.state.view === 'contacts' ? 'selected' : ''} onClick={() => { this.onChangeViewClick('contacts') }}>Contacts</button>
                                    </div>
                                </div>
                                {!this.state.loadingEntries ? (this.state.view === 'entries' ? (this.state.entries.length > 0 ? (
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Updated At</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.entries.map((entry, index) => {
                                                    return entry.user.user_info && (entry.user.user_info.email || entry.user.user_info.user.phone_number) && (
                                                        <tr key={index}>
                                                            <td>{entry.user.user_info.name ? entry.user.user_info.name : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{entry.user.user_info.email ? validator.isEmail(entry.user.user_info.email) ? <a href={`mailto:${entry.user.user_info.email}`} rel="noreferrer" target="_blank">{entry.user.user_info.email}</a> : entry.user.user_info.email : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{entry.user.user_info.phone_number ? entry.user.user_info.phone_number : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{toTimestamp(entry.updatedAt)}</td>
                                                            <td><Tooltip title="View contacts" arrow><div className="show_referrals_link" onClick={() => { this.handleAllInfoTrigger(entry) }}>{entry.contacts.length} contacts submitted</div></Tooltip></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.hasMore && <div><LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/></div>}
                                    </div>
                                ) : <span>No leads found</span>) : (this.state.contacts.length > 0 ? (
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Formatted Name</th>
                                                    <th>Emails</th>
                                                    <th>Phones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.contacts.map((contact, index) => (
                                                    <tr>
                                                        <td>{contact.name.first_name}</td>
                                                        <td>{contact.name.last_name}</td>
                                                        <td>{contact.name.formatted_name}</td>
                                                        <td><ul>{contact.emails.map(email => <li>{email}</li>)}</ul></td>
                                                        <td><ul>{contact.phones.map(phone => <li>{phone}</li>)}</ul></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {this.state.hasMore && <div><LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/></div>}
                                    </div>
                                ) : <span>No contacts found</span>)) : <Preloader padding="30px 0"/> }
                            </div>
                        </div>
                    </div>
                </PageContainer>
                {this.state.selectedEntry && <ReferralModal isOpen={this.state.selectedEntry ? true : false} entry={this.state.selectedEntry} onRequestClose={() => { this.handleAllInfoTrigger(undefined) }}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ReferralPage);