import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Preloader from '../Preloader';
import { getEntry } from '../../services/entry';
import { toDateOnly } from '../../services/dates';

class ReferralModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingEntry: true
        }
    }

    async componentDidMount(){
        const entry = await getEntry(this.props.auth.selectedChatbot.token, this.props.entry._id);
        this.setState({ entry, loadingEntry: false })
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="referral_modal modal modal__preloader">
                <div className="modal__title">{(this.props.entry.user.user_info && this.props.entry.user.user_info.name) ? this.props.entry.user.user_info.name : 'Entry Info'}</div>
                <div className="timestamp">Last updated: {toDateOnly(this.props.entry.updatedAt)}</div>
                {!this.state.loadingEntry ? (
                    <table className="referral_contact_table table mt-medium">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Formatted Name</th>
                                <th>Emails</th>
                                <th>Phones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.entry.contacts && this.state.entry.contacts.length > 0 ? this.state.entry.contacts.map(contact => (
                                <tr>
                                    <td>{contact.name.first_name}</td>
                                    <td>{contact.name.last_name}</td>
                                    <td>{contact.name.formatted_name}</td>
                                    <td><ul>{contact.emails.map(email => <li>{email}</li>)}</ul></td>
                                    <td><ul>{contact.phones.map(phone => <li>{phone}</li>)}</ul></td>
                                </tr>
                            )) : <tr><td colSpan={5}>No contacts submitted</td></tr>}
                        </tbody>
                    </table>
                ) : <Preloader/>}
                <div className="modal__actions mt-medium">
                    <div className="btn cancel" onClick={(e) => { this.props.onRequestClose() }}>Cancel</div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ReferralModal);