import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import UserInfoItem from './UserInfoItem';
import { titleCase } from '../../services/misc';

class UserInfoAttachmentModal extends React.Component{
    render(){
        let userInfo = this.props.userInfoAttachment ? Object.keys(this.props.userInfoAttachment).map((key) => ({ key, value: this.props.userInfoAttachment[key] })) : null;
        if(userInfo){
            userInfo = userInfo.sort(function(x,y){ return x.key === "phone_number" ? -1 : y.key === "phone_number" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return x.key === "email" ? -1 : y.key === "email" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return x.key === "name" ? -1 : y.key === "name" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return (typeof x.value === "object" && x.value.url) ? 1 : (typeof y.value === "object" && y.value.url) ? -1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return typeof x.value === "object" && !x.value.url ? 1 : typeof y.value === "object" && !y.value.url ? -1 : 0; });
        }
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="modal modal__preloader">
                <div className="modal__title">{titleCase(this.props.attachmentName)}</div>
                {userInfo.map((value, index) => <UserInfoItem key={index} keyName={value.key} objectName={this.props.attachmentName} value={this.props.userInfoAttachment[value.key]} user={this.props.user}/>)} 
                <div className="modal__actions mt-medium">
                    <div className="btn button_fit" onClick={(e) => { this.props.onRequestClose() }}>Close</div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(UserInfoAttachmentModal);