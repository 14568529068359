import React from 'react';
import { toTimeOnly } from '../../services/dates';
import ChatMessageAvater from './ChatMessageAvater';
import MessageMedia from './MessageMedia';
import { bold, italic } from '../../services/misc';
import linkifyHtml from "linkify-html";

export default class Message extends React.Component{
    render(){
        const message = this.props.message;
        const messageData = message.message_data;

        if(messageData.text){
            messageData.text = linkifyHtml(bold(italic(messageData.text)))
        }

        let senderClass;
        switch(message.sender){
            case 'user':
                senderClass = 'user';
                break;
            case 'bot':
                senderClass = 'bot';
                break;
            default: 
                senderClass = 'agent';
                break;
        }

        let prompts = [];
        if(messageData.meta && messageData.meta.prompts){
            messageData.meta.prompts.forEach(prompt => {
                prompts.push(prompt)
            })
        }else if(messageData.meta && messageData.meta.suggested_actions){
            messageData.meta.suggested_actions.forEach(suggestedAction => {
                prompts.push(suggestedAction.title)
            })
        }else if(messageData.meta && messageData.meta.list_message && messageData.meta.list_message.sections){
            messageData.meta.list_message.sections.forEach(section => {
                section.options.forEach(option => {
                    
                    prompts.push(option.title)
                })
            })
        }
        
        if(messageData && messageData.text && (messageData.meta && messageData.meta.is_start_message)){
            return <div></div>
        }

        return (
            <div className={`message ${senderClass}`}>
                <div className={`message_inner ${message.agent_sender_object && message.new_message && !message.sending && message.agent_sender_object._id.toString() === this.props.currentAgent._id.toString() ? 'no_animation' : ''}`}>
                    <ChatMessageAvater conversation={this.props.conversation} message={message}/>
                    <div className="message_inner__content">
                        <div className={`message__buble ${message.internal ? 'internal' : ''}`}>{
                            (messageData.media && messageData.media.length > 0) && (
                                <div className="message__media">{messageData.media.map(media => <MessageMedia key={media._id} media={media} handleMediaClick={this.props.handleMediaClick}/>)}</div>
                            ) 
                        }{messageData.text && (
                            <div className="message__text" dangerouslySetInnerHTML={{ __html: messageData.text }}/>
                        )}
                        </div>  
                        {messageData.meta && (
                            <div className="message__meta">
                                {(prompts.length > 0) && (
                                    <div className={`message__prompts ${prompts.length > 3 ? 'hero-card' : ''}`}>{prompts.map((prompt, index) => {
                                        return <div className="message_prompt" key={index}>{prompt}</div>
                                })}</div>
                                )}
                            </div>
                        )}
                        <div className="message__timestamp">{message.internal && 'Internal | '}{message.sending ? 'Sending...' : toTimeOnly(message.createdAt)}</div>
                    </div>  
                </div>
            </div>
        );
    }
}