import { handleResponse } from './misc';

const getEntries = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/entries' + queryString, requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}

const getContacts = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/contacts' + queryString, requestOptions).then(handleResponse).then((contacts) => {
        return contacts;
    });
}

const getEntry = (authToken, entryId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/entry/' + entryId, requestOptions).then(handleResponse).then((entry) => {
        return entry;
    });
}

const exportEntries = (authToken, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    if(filters){
        queryString += '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/entries/export' + queryString, requestOptions).then((entries) => {
        return entries;
    });
}

const exportContacts = (authToken, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    if(filters){
        queryString += '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/contacts/export' + queryString, requestOptions).then((contacts) => {
        return contacts;
    });
}

const searchEntries = (authToken, limit, searchTerm, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }

    return fetch('/api/leads/search/' + searchTerm + queryString, requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}

export { getEntry, getEntries, getContacts, searchEntries, exportEntries, exportContacts };